import React, { useMemo } from 'react';
import Accordion from 'components/bricks/components/shared/components/accordion';
import AdSetupInputWrapper from 'components/bricks/components/shared/components/ad-setup/components/ad-setup-input-wrapper';
import CreativeSelector from 'components/input/CreativeSelector';
import { FieldSetCopy } from 'components/input/Copy';
import { FieldSetSelectInput } from 'components/input/SelectInput';
import Translation from 'components/data/Translation';
import { CreativeV2 } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import { useAdSetupContext } from 'components/bricks/components/shared/components/ad-setup/context/ad-setup-context';
import { withResources } from 'components/data/Resources';
import ValidateHelpers from 'components/bricks/helpers/validate.helpers';
import Divider from 'components/ui-components-v2/Divider';
import brickSetup from './setup';

import './styles/ad-setup.scss';

interface Props {
    handleSelectCreatives: (creatives: CreativeV2[]) => void;
    facebook_cta: {
        [key: string]: string;
    };
}

const SingleFrame: React.FC<Props> = ({ handleSelectCreatives, facebook_cta }) => {
    const { adSetup, updateAdSetupProperty, creatives, selectedFrame } = useAdSetupContext();

    const inputValidators = useMemo(() => {
        return {
            message: ValidateHelpers.getInputValidators('meta_ad', `meta_ad-ad_setup-message`),
            name: ValidateHelpers.getInputValidators('meta_ad', `meta_ad-ad_setup-name-${selectedFrame}`),
            description: ValidateHelpers.getInputValidators('meta_ad', `meta_ad-ad_setup-description-${selectedFrame}`),
            link: ValidateHelpers.getInputValidators('meta_ad', `meta_ad-ad_setup-link-${selectedFrame}`),
            caption: ValidateHelpers.getInputValidators('meta_ad', `meta_ad-ad_setup-caption-${selectedFrame}`)
        };
    }, [selectedFrame]);

    const { multiple, canEditCreative, selectors, templateTypes = [] } = brickSetup?.config?.general?.creativeSelector || {};

    return (
        <Accordion square title={Translation.get('adSetup.main', 'bricks')}>
            <div className="ad-setup__accordion__inputs">
                <CreativeSelector
                    onMutation={(creatives) => handleSelectCreatives(creatives)}
                    multiple={multiple}
                    canEditCreative={canEditCreative}
                    value={creatives}
                    selectors={selectors}
                    templateTypes={templateTypes}
                />
                <AdSetupInputWrapper>
                    <FieldSetCopy
                        label={Translation.get('adSetup.metaAd.message', 'bricks')}
                        useValueObject={false}
                        onMutation={(newValue) => updateAdSetupProperty('message', newValue)}
                        value={adSetup.message}
                        validators={inputValidators.message}
                    />
                </AdSetupInputWrapper>
                <AdSetupInputWrapper>
                    <FieldSetCopy
                        label={Translation.get('adSetup.metaAd.headline', 'bricks')}
                        useValueObject={false}
                        onMutation={(newValue) => updateAdSetupProperty('name', newValue, true)}
                        value={adSetup?.items?.[0].name}
                        validators={inputValidators.name}
                    />
                </AdSetupInputWrapper>
                <AdSetupInputWrapper>
                    <FieldSetCopy
                        label={Translation.get('adSetup.description', 'bricks')}
                        onMutation={(newValue) => updateAdSetupProperty('description', newValue, true)}
                        value={adSetup?.items?.[0].description}
                        useValueObject={false}
                        validators={inputValidators.description}
                    />
                </AdSetupInputWrapper>
                <AdSetupInputWrapper>
                    <FieldSetSelectInput
                        label={Translation.get('adSetup.metaAd.callToAction.copy', 'bricks')}
                        useValueObject={false}
                        value={adSetup.cta && facebook_cta[adSetup.cta] ? adSetup.cta : ''}
                        onMutation={(newValue) => updateAdSetupProperty('cta', newValue)}
                        options={facebook_cta}
                    />
                </AdSetupInputWrapper>

                <Divider />
                <AdSetupInputWrapper>
                    <FieldSetCopy
                        label={Translation.get('adSetup.metaAd.websiteUrl', 'bricks')}
                        useValueObject={false}
                        onMutation={(newValue) => updateAdSetupProperty('link', newValue, true)}
                        value={adSetup?.items?.[0].link}
                        validators={inputValidators.link}
                    />
                </AdSetupInputWrapper>
                <AdSetupInputWrapper>
                    <FieldSetCopy
                        label={Translation.get('adSetup.metaAd.displayUrl', 'bricks')}
                        useValueObject={false}
                        onMutation={(newValue) => updateAdSetupProperty('caption', newValue, true)}
                        value={adSetup?.items?.[0].caption}
                        validators={inputValidators.caption}
                    />
                </AdSetupInputWrapper>
            </div>
        </Accordion>
    );
};

export default withResources(SingleFrame, ['facebook_cta']);
